@tailwind base;
@tailwind components;
@tailwind utilities;

.gradiant-bg {
  background: linear-gradient(45deg, rgba(183,44,201,1) 16%, rgba(48,160,205,1) 100%);
}

.mob-view {
  display: none;
}

.bg-opacity {
  background-color: rgba(0, 0, 0, .5);
}

/* body {
  min-height: 100vh;
} */

@media screen and (max-width: 675px) {
  .desk-view{ 
    display: none;
  }

  .mob-view {
    display: block;
  }
}